import React from 'react'
import Core from '../templates/Core'
import Header from '../page_fragments/Header'
const NotFoundPage = ({ location }) => (
  <Core location={location}>
    <Header />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Core>
)

export default NotFoundPage
